import React, { useContext, useEffect, useState } from 'react';
import HomePageBanner from './HomePageBanner';
import HomePageSection from './HomePageSection';
import HomePageNewRelease from './HomePageNewRelease';
import Mysurvey from "../Survey/Surveydisplay";
import { AppContext } from '../../core/app-context';
import api from 'core/api';

const HomePage = () => {

  const [appState, dispatch] = useContext(AppContext);
  const [showSurvey, setShowSurvey] = useState(!appState.surveyComplete);

  useEffect(() => {
    if (!appState.user && appState.psid) {
      api
        .get(`/queries/getUserByPsid/${appState.psid}`, {})
        .then((result) => {
          if (result.data)
            if (result.data[0]) {
              dispatch({
                type: "setUser",
                value: result.data[0]
              });
            } else
              console.log("invalid response from getUserByPsid");
          else
            console.log("invalid response from getUserByPsid");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [])
  useEffect(() => {
    console.log("dismissed!");
    setShowSurvey(!JSON.parse(localStorage.getItem('zamaClientAppState')).surveyComplete);
  }, [appState])
  useEffect(() => {
    console.log(showSurvey);
    console.log(appState.surveyComplete);
  }, [showSurvey])

  return (
    <>
      <HomePageBanner />
      <HomePageSection />
      <HomePageNewRelease />
      {
        showSurvey &&
        <Mysurvey
          completeSurvey={() => {
            dispatch({
              type: "completeSurvey"
            });
            setShowSurvey(false);
          }}
        />
      }
    </>
  )
}

export default HomePage;
