import axios from 'axios';

const BASE_URL = 'https://zama-backend.herokuapp.com';

async function get(route, params) {
    let query = Object.keys(params).length > 0 ?
        "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&')
        : "";
    return axios.get(BASE_URL + route + query);
}

async function post(route, params) {
    return axios.post(BASE_URL + route, params);
}

const api = { get, post };

export default api;