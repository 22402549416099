import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

document.cookie.split(";").forEach((c) => {
  document.cookie = c
    .replace(/^ +/, "")
    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
});

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
