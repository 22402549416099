import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getUnixTimestamp } from 'Utils/helperFunctions';
import { AppContext } from '../../core/app-context';

export default function AuthUser() {

    const params = useParams();
    const history = useHistory();

    const [appState, dispatch] = useContext(AppContext);
    const [errorMsg, setErrorMsg] = useState("");

    if (params.psid) {
        dispatch({
            type: "setPSID",
            value: params.psid
        });
        history.replace('/');
    } else {
        setErrorMsg("Something went wrong");
    }
    return (
        <>
            {
                errorMsg
                    ? <div style={{ height: "100vh", width: "100vw" }}>
                        <p style={{ marginTop: "48vh", textAlign: "center" }}>
                            {errorMsg}
                        </p>
                    </div>
                    : <div style={{ height: "100vh", width: "100vw" }}>
                        <p style={{ marginTop: "48vh", textAlign: "center" }}>
                            Loading component...
                        </p>
                    </div>
            }
        </>
    );
}
