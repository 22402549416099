import React, { useContext } from "react";
import "survey-react/survey.css";
import * as Suvery from "survey-react";
import { json } from "./Questions";
import { Box } from "@chakra-ui/react";
import api from "core/api";
import { AppContext } from "../../core/app-context";

const API_ENDPOINT = "https://gentle-ocean-93270.herokuapp.com/survey";

const Mysurvey = (props) => {

    const [appState, dispatch] = useContext(AppContext);

    return (
        <Box
            position="fixed"
            w="100%"
            h="100%"
            top={0}
            left={0}
            bottom={0}
            right={0}
            backgroundColor="rgba(0,0,0,0.5)"
            zIndex={10}
        >
            <Box
                pos="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
            >
                <Suvery.Survey
                    json={json}
                    showCompletedPage={false}
                    onComplete={(survey) => {
                        console.log("onComplete", survey);
                        survey.onComplete.add(function (sender, options) {
                            console.log("sender", sender);

                            options.showDataSaving();
                            //   var xhr = new XMLHttpRequest();
                            //   xhr.open("POST", API_ENDPOINT);
                            //   xhr.setRequestHeader(
                            //     "Content-Type",
                            //     "application/json; charset=utf-8"
                            //   );
                            //   xhr.onload = xhr.onerror = function () {
                            //     if (xhr.status == 200) {
                            //       options.showDataSavingSuccess();
                            //     } else {
                            //       options.showDataSavingError();
                            //     }
                            //   };
                            console.log("sender", sender.data);
                            //   xhr.send(JSON.stringify(sender.data));
                            let payload = sender.data;
                            api
                                .post('/survey', {
                                    user_uid: appState.user.user_uid,
                                    question1: "Gender",
                                    question1_answer: payload["Gender"],
                                    question2: "Shoe Size",
                                    question2_answer: payload["Shoes Size"],
                                    question3: "Shirt Size",
                                    question3_answer: payload["T-Shirt Size"],
                                    question4: "Height",
                                    question4_answer: payload["height"]
                                })
                                .then((result) => {
                                    console.log(result.data);
                                })
                                .catch((e) => {
                                    console.log(e);
                                })
                        });

                        setTimeout(() => {
                            props.completeSurvey();
                        }, 1000);
                    }}
                />
            </Box>
        </Box>
    );
};

export default Mysurvey;
