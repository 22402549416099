const checkboxActive = <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
  <g id="checkbox-checked" transform="translate(-322 -973)">
    <g id="Rectangle_30" data-name="Rectangle 30" transform="translate(322 973)" fill="none" stroke="#707070" strokeWidth="2">
      <rect width="13" height="13" rx="3" stroke="none" />
      <rect x="1" y="1" width="11" height="11" rx="2" fill="none" />
    </g>
    <path id="iconfinder_check_2561355" d="M9.487,6,5.715,11.963,4,9.252" transform="translate(321.756 970.519)" fill="none" stroke="#687774" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </g>
</svg>


export default checkboxActive;