export const json = {
  title: "Welcome to Mr Price",
  pages: [
    {
      title: "",
      questions: [
        {
          type: "radiogroup",
          name: "Gender",
          title: "Are you a ?",
          hasOther: false,
          isRequired: true,
          choices: ["Male", "Female"],
        },
      ],
    },
    {
      questions: [
        {
          type: "radiogroup",
          name: "T-Shirt Size",
          title: "What is your T-Shirt Size?",

          isRequired: true,
          choices: ["Small", "Meduim", "Large", "Extra large"],
        },
      ],
    },
    {
      questions: [
        {
          type: "radiogroup",
          name: "Shoes Size",
          title: "What is your Shoes size? UK Size?",

          isRequired: true,
          choices: ["3-6", "7-10", "11-14"],
        },
      ],
    },
    {
      questions: [
        {
          type: "radiogroup",
          name: "height",
          title: "Whats your height?",

          isRequired: true,
          choices: ["156 - 161 cm", "178 - 180cm ", "190 - 200cm"],
        },
      ],
    },
  ],
};
