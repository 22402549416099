export default ({
  colors: {
    default: '#F7F3F5!important',
    black: '#403234!important',
    grey: '#707070!important',
    cream: '#e7e7e7!important',
    orange: '#FFC107!important',
    darkGrey: '#687774!important',
    transparent: 'transparent!important',
  },
  clothingPallete: {
    white: '#fff!important',
    black: '#000!important',
    red: '#FF0000!important',
    yellow: '#FFFF00!important',
    grey: '#555555!important',
    blue: '#0800FF!important',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})
