import React from 'react';
import Mysurvey from './Surveytype';

const Suvery = (props) => {
    return (
        <div>
            <Mysurvey {...props} />
        </div>
    );
}

export default Suvery