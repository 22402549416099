import React from 'react';
import ButtonSize from 'Components/Buttons/ButtonCube';
import { Button } from '@chakra-ui/react';


const ProductSelectionSize = () => {
  return (
    <div className='mt-4'>
      <h4>
        What would you pay for this product?
      </h4>
      <div className='d-flex justify-content-center mt-3'>
        <Button
          mx={3}
          py={12}
          fontWeight={700}
          borderRadius={6}
          boxShadow="md"
        >
          R50-R150
        </Button>
        <Button
          mx={3}
          py={12}
          fontWeight={700}
          borderRadius={6}
          boxShadow="md"
        >
          R150-R250
        </Button>
        <Button
          mx={3}
          py={12}
          fontWeight={700}
          borderRadius={6}
          boxShadow="md"
        >
          R250-R350
        </Button>
        {/* 
          <ButtonSize text='R50-R180' />
          <ButtonSize text='R150-R200' />
          <ButtonSize text='R250-R300' /> 
        */}
      </div>
    </div>
  )
}

export default ProductSelectionSize;