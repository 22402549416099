import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useSwipeable } from 'react-swipeable';
import Interactions from 'Components/Product/Interactions';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import {
    Box,
    Center,
    CircularProgress,
    Flex,
    Grid, GridItem,
    Image,
} from '@chakra-ui/react';

let initImgHeight;

export default function ProductBrowser(props) {

    const history = useHistory();
    const topRef = useRef(null);
    const imgRef = useRef(null);
    const { id, commentOpen, imgArray, onComment, onNext, onPrev, shareOpen, onShare } = props;

    const [prodImg, selectProdImg] = useState(0);
    const [showInteractions, setShowInteractions] = useState(true);
    const [imgScale, setImgScale] = useState(1);
    const [images, setImages] = useState([]);

    const handlers = useSwipeable({
        // onSwiped: (eventData) => console.log("User Swiped!", eventData),
        onSwipedLeft: (eventData) => {
            onNext();
            if (commentOpen)
                onComment();
            if (shareOpen && onShare)
                onShare();
        },
        onSwipedRight: (eventData) => {
            onPrev();
            if (commentOpen)
                onComment();
            if (shareOpen && onShare)
                onShare();
        }
    });

    const handleZoom = (e) => {
        let imageScale = e.state.scale;
        setImgScale(imageScale > 1.0 ? imageScale : 1);
    };


    useEffect(() => {
        topRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center"
        });
    }, []);
    useEffect(() => {
        setShowInteractions(imgScale === 1);
    }, [imgScale]);
    useEffect(() => {
        if (images.length > 0)
            initImgHeight = imgRef.current.height;
    }, [images]);
    useEffect(() => {
        if (imgArray) {
            let newImages = [];
            imgArray.map((img, index) => {
                newImages.push({
                    original: `${img}`,
                    thumbnail: `${img}`,
                });
            });
            setImages(newImages);
        }
    }, [imgArray]);

    console.log(images);

    return (
        <div style={{ position: 'relative' }} {...handlers}>
            <div ref={topRef} />
            <Box>
                <TransformWrapper
                    onZoom={handleZoom}
                    onPinching={handleZoom}
                    panning={{
                        disabled: showInteractions
                    }}
                >
                    <TransformComponent>
                        <Image
                            ref={imgRef}
                            maxW="100vw"
                            minW="100vw"
                            // w="100vw"
                            src={images.length > 0 ? images[prodImg] ?  images[prodImg].original ? images[prodImg].original : "" : "" : ""}
                            fit="cover"
                            h={initImgHeight ? initImgHeight * imgScale : "400px"}
                        />
                    </TransformComponent>

                </TransformWrapper>
                <Grid templateColumns="repeat(5, 1fr)" gap={2} px={2}>
                    {images.map((img, imgIdx) => {

                        return (
                            <GridItem
                                key={`product-${id}-img-thumbnail-${imgIdx + 1}`}
                                colSpan={1}
                                w={60}
                                h={60}
                                cursor="pointer"
                                onClick={() => {
                                    selectProdImg(imgIdx);
                                }}
                            >
                                <Box
                                    bg={imgIdx === prodImg ? "lightblue" : "transparents"}
                                    borderRadius={6}
                                    p={3}
                                >
                                    <Image
                                        w="100%"
                                        h="100%"
                                        borderRadius={6}
                                        src={img.thumbnail}
                                    />
                                </Box>
                            </GridItem>
                        );
                    })}
                </Grid>
            </Box>
            {showInteractions &&
                <Interactions
                    productId={id}
                    onComment={onComment}
                    commentOpen={commentOpen}
                    onShare={onShare}
                    shareOpen={shareOpen}
                    marginTop={20}
                />
            }
            <div style={{
                fontWeight: 500,
                position: 'absolute',
                top: 8,
                left: 20,
                textAlign: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.3)'
            }}>
                {
                    showInteractions &&
                    <div
                        href="/clothingstoretemplate/"
                        style={{ display: 'flex' }}
                        onClick={() => { history.replace("/clothingstoretemplate/") }}
                    >
                        <MdNavigateBefore size={35} color="white" />
                    </div>
                }
            </div>
        </div>

    );
}
