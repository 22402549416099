import React from 'react';
import InputField from 'Components/InputField/InputField';

const AddressField = () => {
  return (
    <InputField placeholder='Address' />
  )
}


export default AddressField;