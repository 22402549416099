import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { theme } from 'common/@mui';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { ThemeProvider } from '@material-ui/styles';
import Layout from 'Layout';
import { RenderRoutes } from 'Routes';
import { StoreContextProvider } from 'Store/StoreContext';
import { StateProvider } from './core/app-context';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <StateProvider>
        <StoreContextProvider>
          <Router>
            <Layout>
              <RenderRoutes />
            </Layout>
          </Router>
        </StoreContextProvider>
      </StateProvider>
    </ThemeProvider >

  );
}


export default App;