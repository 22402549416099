import React, { useState, useEffect } from "react"
import "./comments.css"

const CustomInput = ({ cancellor,
  parentId,
  value,
  edit,
  submit,
  handleCancel,
}) => {
  const [text, setText] = useState(value)

  const handleChange = (e) => {
    setText(e.target.value)
  }
  return (
    <div>
      <div className="form">

        <div className="row">
          <div className="input-div">
            <textarea
              rows="2"
              className="input-box"
              type='text'
              placeholder='Type your Comment here.'
              component='input'
              value={text}
              onChange={handleChange}></textarea>
          </div>
        </div>
      </div>
      <div className="btn-div">
        <button
          className="post-btn"
          onClick={() => submit(cancellor, text, parentId, edit, setText)}
          type="submit"
          disabled={!text}
        >
          Post
        </button>
        <button className="cancel-btn"
          onClick={() => handleCancel(cancellor, edit)}>
          Cancel
        </button>
      </div>
    </div>
  );
}

export default CustomInput
