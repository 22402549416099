import { useState, useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";
// import { products } from 'Api/ClothingDatabase';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ProductViewer from "Components/Carousel/ProductViewer";
import ProductSelection from "../Product/ProductSelection";
import ProductBrowser from 'Components/ProductBrowser';
import api from 'core/api';


export default function CatalogueSlide() {

    const { id } = useParams();

    const [products, setProducts] = useState([]);
    const [prods, setProds] = useState([]);
    const [catIdx, setCatIdx] = useState(0);
    const [commentOpen, setCommentOpen] = useState(false);
    const [shareOpen, setShareOpen] = useState(false);

    function onPrev() {
        if (catIdx > 0)
            setCatIdx(catIdx - 1);
    }

    function onNext() {
        if (catIdx + 1 < prods.length)
            setCatIdx(catIdx + 1);
    }

    function onComment() {
        setCommentOpen(!commentOpen);
    }

    function onShare() {
        setShareOpen(!shareOpen);
    }

    useEffect(() => {
        api
            .get('/products', {})
            .then((result) => {
                setProducts(result.data);
            })
            .catch((e) => {
                console.log(e);
            })
    }, [])

    useEffect(() => {
        setProds(products.filter((product) => product.category.toLowerCase() === id.toLowerCase()));
    }, [products]);

    return (
        <div style={{ maxWidth: "100vw", overflowX: "hidden" }}>
            <div>
                <Row style={{ margin: 0 }}>
                    <Col xs={12} lg={6} style={{ padding: 0 }}>
                        {
                            prods.length > 0 &&
                            <ProductBrowser
                                imgArray={[
                                    prods[catIdx].img_front_view,
                                    prods[catIdx].img_side_view_b,
                                    prods[catIdx].img_side_view_l,
                                    prods[catIdx].img_side_view_r
                                ]}
                                id={prods[catIdx].product_uuid}
                                showInteractions={true}
                                onNext={onNext}
                                onPrev={onPrev}
                                onComment={onComment}
                                commentOpen={commentOpen}
                                onShare={onShare}
                                shareOpen={shareOpen}
                            />
                        }
                    </Col>
                    <Col xs={12} lg={6}>
                        {
                            prods.length > 0 &&
                            <ProductSelection
                                current={prods[catIdx]}
                                title={prods[catIdx].productname}
                                id={prods[catIdx].product_uuid}
                                commentOpen={commentOpen}
                                shareOpen={shareOpen}
                                onComment={onComment}
                                onShare={onShare}
                            />
                        }
                    </Col>
                    <Col xs={12}></Col>
                </Row>
            </div>
        </div>
    )
}
