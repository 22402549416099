import { useContext, useEffect, useState } from 'react';
import {
    Box,
    Image,
    Slide,
    useDisclosure
} from '@chakra-ui/react';
import { AiFillLike, AiFillDislike } from 'react-icons/ai';
import { MdComment } from 'react-icons/md';
import { IoIosShareAlt } from 'react-icons/io';
import { AppContext } from '../../core/app-context';
import { Button } from 'bootstrap';
import api from 'core/api';

export default function Interactions({ productId, onComment, commentOpen, onShare, shareOpen, marginTop }) {

    const [appState, dispatch] = useContext(AppContext);

    const [show, setShow] = useState(true);
    const [toastMessage, setToastMessage] = useState("");

    const toastDisclosure = useDisclosure();

    const [interactions, setInteractions] = useState([
        { name: 'Like', src: '/icons/interactions/like.svg', icon: AiFillLike, selected: false },
        { name: 'Dislike', src: '/icons/interactions/dislike.svg', icon: AiFillDislike, selected: false },
        { name: 'Comment', src: '/icons/interactions/like.svg', icon: MdComment, selected: false },
        { name: 'Share', src: '/icons/interactions/comment.svg', icon: IoIosShareAlt, selected: false }
    ]);

    const [forceStateChange, setforceStateChange] = useState(true);

    useEffect(() => {
        if (toastMessage) {
            toastDisclosure.onOpen();
            setTimeout(() => {
                toastDisclosure.onClose();
                setToastMessage("");
            }, 2000);
        }
    }, [toastMessage])

    return (
        <>
            <div style={{
                fontWeight: 500,
                position: 'absolute',
                top: marginTop ?? '18%',
                right: 20,
                textAlign: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.3)'
            }}>
                {
                    interactions.map((interaction, idx) => {
                        let isSelected = false;
                        if (appState.productEngagement[productId]) {
                            if (appState.productEngagement[productId][interaction.name.toLowerCase()])
                                isSelected = true;
                        }
                        if (interaction.name.toLowerCase() === 'comment') {
                            if (commentOpen)
                                isSelected = true;
                            else
                                isSelected = false;
                        }
                        if (interaction.name.toLowerCase() === 'share') {
                            if (shareOpen)
                                isSelected = true;
                            else
                                isSelected = false;
                        }

                        return (
                            <Box
                                key={idx}
                                color={isSelected ? "#0661a9" : "white"}
                                my={12}
                                cursor="pointer"
                                onClick={() => {
                                    try {
                                        interaction.onclick();
                                    } catch (e) { }

                                    if (interaction.name.toLowerCase() !== 'comment'
                                        && interaction.name.toLowerCase() !== 'share') {
                                        console.log(`${interaction.name.toLowerCase()}Prod`);
                                        dispatch({
                                            type: `${interaction.name.toLowerCase()}Prod`,
                                            id: productId
                                        });
                                        api
                                            .post('/products/likeProduct', {
                                                user_uid: appState.user.user_uid,
                                                product_uuid: productId,
                                                likes: interaction.name.toLowerCase() === 'like' ? 1 : 0
                                            })
                                            .then(({ data }) => {
                                                setToastMessage(`You ${interaction.name.toLowerCase() === 'like' ? "liked" : "disliked"} this item!`)
                                                setforceStateChange();
                                            })
                                            .catch((e) => {
                                                console.log(e);
                                            })
                                    }

                                    if (interaction.name.toLowerCase() === 'comment') {
                                        onComment();
                                    }
                                    if (interaction.name.toLowerCase() === 'share') {
                                        onShare();
                                    }

                                    setforceStateChange(!forceStateChange);
                                }}
                            >
                                <p>{interaction.name}</p>
                                {/* <Image
                                width={30}
                                src={`/icons/interactions/${interaction.name.toLowerCase()}${interaction.selected ? "_selected" : ""}.svg`}
                                alt={`${interaction.name.toLowerCase()}-icon`}
                            /> */}
                                <interaction.icon color={isSelected ? '#0661a9' : 'white'} size={30} />
                            </Box>
                        );
                    })
                }
            </div>
            <Slide direction="bottom" in={toastDisclosure.isOpen} style={{ zIndex: 100 }}>
                <Box
                    p="40px"
                    color="white"
                    mt="4"
                    bg="#0661a9"
                    rounded="md"
                    shadow="md"
                    fontWeight={500}
                    textAlign="center"
                >
                    {toastMessage}
                </Box>
            </Slide>
        </>
    );
}
