import React from 'react';
import InputField from 'Components/InputField/InputField';

const CityField = () => {
  return (
    <InputField placeholder='City' />

  )
}


export default CityField;