import React from 'react';
import ConnectSocialMedia from '../../Components/ConnectSocialMedia';


const FacebookField = () => {
  return (
    <ConnectSocialMedia text='Login in with Facebook' icon='/icons/socialMedia/facebook.svg' />
  )
}

export default FacebookField;