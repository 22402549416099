import React, { useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import ProductSelectionSize from './ProductSelectionSize';
import ProductSelectionColor from './ProductSelectionColor';
import ButtonSemiRound from 'Components/Buttons/ButtonSemiRound';
import UseStore from 'Store/StoreContext';
import { withRouter } from 'react-router-dom';
import Comments from '../../Commnets/Comments';
import { Collapse } from '@chakra-ui/react';
import Share from "../../Share/Share";
import { AppContext } from 'core/app-context';
import api from 'core/api';


const Product = (props) => {
  const classes = useStyles()
  const { current, } = props;
  const { cart, setCart } = UseStore();
  const commentRef = useRef(null);
  const shareRef = useRef(null);

  const [appState, dispatch] = useContext(AppContext);

  const addToCart = (curr) => {
    setCart([curr, ...cart])
    // localStorage.setItem('cart', cart)
    // console.log(localStorage.getItem('cart'))
  }

  useEffect(() => {
    if (props.commentOpen) {
      commentRef.current.scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [props.commentOpen]);
  useEffect(() => {
    if (props.shareOpen) {
      shareRef.current.scrollIntoView({
        behavior: "smooth"
      });
    }
  }, [props.shareOpen]);


  console.log(current);
  return (
    <div className={classes.selection}>
      <h2 className={classes.title} >{current.productname}</h2>

      <Collapse ref={commentRef} in={props.commentOpen ?? false}>
        <Comments
          submit={(cancellor, text, parentId, edit, setText) => {
            console.log(text);
            api
              .post('/products/userCommentOnProduct', {
                user_uid: appState.user.user_uid,
                product_uuid: current.product_uuid,
                comment: text
              })
              .then(({ data }) => {
                console.log(data);
                props.onComment();
              })
              .catch((e) => {
                console.log(e);
              });
          }}
          handleCancel={() => {
            props.onComment();
          }}
        />
      </Collapse>
      <Collapse ref={shareRef} in={props.shareOpen ?? false}>
        <Share />
      </Collapse>

      <ProductSelectionSize />
      <ProductSelectionColor />
      <div className="mt-3 d-flex justify-content-center">
        <ButtonSemiRound color='darkGrey' fontWeight='400' text='Add to wishlist' />
      </div>
      <div className="mt-4 ">
        <div className={classes.buttonContainer}>

        </div>
      </div>
    </div>

  )
}

export default withRouter(Product);


const useStyles = makeStyles((theme) => ({
  selection: {
    color: '#555',
    textAlign: 'center',
  },
  title: {
    color: '#555'
  },
  buttonContainer: {
    width: '300px',
    margin: 'auto'
  }
}));