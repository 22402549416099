import ImageGallery from 'react-image-gallery';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import "react-image-gallery/styles/css/image-gallery.css";
import { makeStyles } from '@material-ui/styles';
import 'swiper/swiper-bundle.css';
import Interactions from 'Components/Product/Interactions';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import { useSwipeable } from 'react-swipeable';
import { SlideFade, useDisclosure } from '@chakra-ui/react';

const ProductViewer = (props) => {

  const history = useHistory();
  const topRef = useRef(null);

  const handlers = useSwipeable({
    // onSwiped: (eventData) => console.log("User Swiped!", eventData),
    onSwipedLeft: (eventData) => {
      props.onNext();
      if (props.commentOpen) {
        props.onComment();
      }
    },
    onSwipedRight: (eventData) => {
      props.onPrev();
      if (props.commentOpen) {
        props.onComment();
      }
    }
  });

  const classes = useStyles();
  const { imgArray } = props;
  const images = [];

  if (imgArray) {
    imgArray.map((img, index) => {
      images.push({
        original: `${process.env.PUBLIC_URL}/images/product/${img}`,
        thumbnail: `${process.env.PUBLIC_URL}/images/product/${img}`,
      })
    })
  }

  useEffect(() => {
    topRef.current.scrollIntoView({
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      {/* <div
        style={{ display: 'flex', width: '100%', justifyContent: 'space-between ' }}
      >
        <div
          style={{ display: 'flex' }}
          onClick={props.onPrev}
        >
          <MdNavigateBefore size={35} />
          <p>Previous</p>
        </div>
        <div
          style={{ display: 'flex' }}
          onClick={props.onNext}
        >
          <p>Next</p>
          <MdNavigateNext size={35} />
        </div>
      </div> */}
      <div style={{ position: 'relative' }} {...handlers}>
        <div ref={topRef} />
        <ImageGallery
          items={images}
          additionalClass={classes.gallery}
          showPlayButton={false}
          thumbnailPosition={'bottom'}
          showNav={false}
          showFullscreenButton={true}
          disableSwipe={true}
        />
        <Interactions
          productId={props.id}
          onComment={props.onComment}
          commentOpen={props.commentOpen}
        />
        <div style={{
          fontWeight: 500,
          position: 'absolute',
          top: 8,
          left: 20,
          textAlign: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.3)'
        }}>
          <div
            href="/clothingstoretemplate/"
            style={{ display: 'flex' }}
            onClick={() => { history.replace("/clothingstoretemplate/") }}
          >
            <MdNavigateBefore size={35} color="white" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductViewer;

const useStyles = makeStyles((theme) => ({
  gallery: {
    '& .image-gallery-thumbnails-container': {
      textAlign: 'left',
    },
    '& .image-gallery-image': {
      objectFit: 'cover'
    },
    '& .image-gallery-slides img': {
      height: 500
    },
  },
}));
