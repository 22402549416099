import { createContext, useReducer } from 'react';
import api from './api';

if (!localStorage.getItem('zamaClientAppState')) {
    localStorage.setItem('zamaClientAppState', JSON.stringify({
        productEngagement: {},
        options: {
            hideHeader: false
        },
        surveyComplete: false,
        loading: false
    }));
}

if (!JSON.parse(localStorage.getItem('zamaClientAppState')).options) {
    localStorage.setItem('zamaClientAppState', JSON.stringify({
        ...JSON.parse(localStorage.getItem('zamaClientAppState')),
        options: {
            hideHeader: false
        },
    }));
}

let initialState = JSON.parse(localStorage.getItem('zamaClientAppState') ?? "{}");

export const AppContext = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        case "setPSID":
            let setPSIDState = state;
            setPSIDState.psid = action.value;
            localStorage.setItem('zamaClientAppState', JSON.stringify(setPSIDState));
            return setPSIDState;
        case "likeProduct":
            let likeProductState = state;
            if (!likeProductState.productEngagement[action.id])
                likeProductState.productEngagement[action.id] = {};
            likeProductState.productEngagement[action.id].like = likeProductState.productEngagement[action.id].like ? !likeProductState.productEngagement[action.id].like : true;
            likeProductState.productEngagement[action.id].dislike = false;
            localStorage.setItem('zamaClientAppState', JSON.stringify(likeProductState));
            return likeProductState;
        case "likeProd":
            let likeProdState = state;
            if (!likeProdState.productEngagement[action.id])
                likeProdState.productEngagement[action.id] = {};
            likeProdState.productEngagement[action.id].like = true;
            likeProdState.productEngagement[action.id].dislike = false;
            localStorage.setItem('zamaClientAppState', JSON.stringify(likeProdState));
            return likeProdState;
        case "dislikeProduct":
            let dislikeProductState = state;
            if (!dislikeProductState.productEngagement[action.id])
                dislikeProductState.productEngagement[action.id] = {};
            dislikeProductState.productEngagement[action.id].dislike = dislikeProductState.productEngagement[action.id].dislike ? !dislikeProductState.productEngagement[action.id].dislike : true;
            dislikeProductState.productEngagement[action.id].like = false;
            localStorage.setItem('zamaClientAppState', JSON.stringify(dislikeProductState));
            return dislikeProductState;
        case "dislikeProd":
            let dislikeProdState = state;
            if (!dislikeProdState.productEngagement[action.id])
            dislikeProdState.productEngagement[action.id] = {};
            dislikeProdState.productEngagement[action.id].dislike = true;
            dislikeProdState.productEngagement[action.id].like = false;
            localStorage.setItem('zamaClientAppState', JSON.stringify(dislikeProdState));
            return dislikeProdState;
        case "completeSurvey":
            let completeSurveyState = state;
            completeSurveyState.surveyComplete = true;
            localStorage.setItem('zamaClientAppState', JSON.stringify(completeSurveyState));
            return completeSurveyState;
        case "setUser":
            let setUserState = { ...state, user: action.value };
            localStorage.setItem('zamaClientAppState', JSON.stringify(setUserState));
            return setUserState;
        case "loading":
            return {
                ...state,
                loading: state ? !state.loading : true
            };
        case "setLoading":
            return {
                ...state,
                loading: true
            };
        case "unsetLoading":
            return {
                ...state,
                loading: false
            };
        case "setState":
            return action.state;
        default:
            console.warn("invalid action");
            return state;
    }
};

export const StateProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={[state, dispatch]}>
            {props.children}
        </AppContext.Provider>
    );
}